import React, { useState } from 'react'
import { Title, ContentReset, Block, Button } from '@superlogica/design-ui'

import * as S from './Faq.styled'

interface faqProps {
  items?: {
    productName: string
    featured?: {
      content: string
      tags: string
      title: string
      url?: {
        url?: string
      }
    }[]
  }[]
}

const sanitize = (text: string) =>
  text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

const Faq: React.FC<faqProps> = ({ items = [] }) => {
  const [expanded, setExpanded] = useState<boolean | string>(false)

  return (
    <S.Wrapper expanded={expanded} setExpanded={setExpanded}>
      {items.map(({ productName, featured }, i) => (
        <S.Item key={`faq-${i}`} accordionId={`faq-${i}`}>
          <S.Heading chevron="babar300">
            <Title tag="h3" size="display03" color="sonic500" weight="bold">
              {productName}
            </Title>
          </S.Heading>
          <S.Body>
            {!!featured &&
              featured.map(({ tags, title, content, url }, i) => (
                <Block
                  borderTop="1px solid"
                  borderColor="babar100"
                  padding={{ xxxs: '16px 0' }}
                  key={`faqbody-${i}`}
                >
                  <Block display="flex" gap={8}>
                    {!!tags &&
                      tags.split(',').map((tag, i) => (
                        <S.Tag key={`tag-${i}`} className={sanitize(tag)}>
                          {tag}
                        </S.Tag>
                      ))}
                  </Block>
                  <Title
                    font="heading"
                    size="display04"
                    color="babar900"
                    weight="semiBold"
                    marginTop={8}
                    marginBottom={16}
                  >
                    {title}
                  </Title>
                  {!!content && <ContentReset body={content} />}
                  {!!url?.url && (
                    <Button
                      tag="a"
                      target="_blank"
                      href={url.url}
                      size="sm"
                      color="sonic500"
                      weight="semiBold"
                      uppercase
                    >
                      Saiba mais
                    </Button>
                  )}
                </Block>
              ))}
          </S.Body>
        </S.Item>
      ))}
    </S.Wrapper>
  )
}

export default Faq
