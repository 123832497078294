import styled, { css } from 'styled-components'
import { Accordion } from 'components/common'
import { parseColor } from '@superlogica/design-ui'

export const Heading = styled(Accordion.Heading)`
  padding: 24px 24px;

  .title {
    flex: 1 1;
    margin-right: 24px;
  }
`

export const Body = styled(Accordion.Body)`
  padding: 0 24px 0;

  .sdl-content-reset {
    padding-bottom: 16px;
    ul {
      margin-top: 12px;
      list-style-type: disc;
      list-style-position: inside;

      li {
        list-style-type: disc;
        list-style-position: inside;
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
      }
    }

    p {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    a {
      font-weight: 700;
    }
  }
`

export const Tag = styled.span`
  padding: 4px 8px;
  font-size: 0.75rem;
  border-radius: 12px;
  font-weight: bold;
  line-height: 1;

  ${({ theme: { colors } }) => css`
    color: ${parseColor(colors.babar0)};
    background-color: ${parseColor(colors.sonic500)};
    &.nova-funcionalidade {
      background-color: ${parseColor(colors.success)};
    }
    &.melhoria {
      background-color: ${parseColor(colors.sonic100)};
    }
  `};
`

export const Item = styled(Accordion.Item)`
  border-radius: 8px;
  ${({ theme: { colors } }) => css`
    border: 1px solid ${parseColor(colors.babar100)};
    background-color: ${parseColor(colors.babar0)};
  `};
`

export const Wrapper = styled(Accordion.Wrapper)`
  display: grid;
  gap: 24px;
`
