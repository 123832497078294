import { Seo } from 'components/helpers'
import { graphql } from 'gatsby'
import React from 'react'
import View from 'views/post'

const SinglePage = ({ data }) => {
  const {
    wp: { infoSuper, allInfoSuper }
  } = data

  const pageTitle = `${infoSuper.vertical.nodes[0].name} - Edição ${infoSuper.title}`

  return (
    <>
      <Seo title={pageTitle} />
      <View data={infoSuper} otherPosts={allInfoSuper.nodes} />
    </>
  )
}

export const query = graphql`
  query single($id: ID!, $databaseId: [ID], $vertical: [String]) {
    wp {
      infoSuper(id: $id, idType: ID) {
        title
        featuredText {
          text
        }
        featuredImage {
          node {
            altText
            sourceUrl
            srcSet(size: LARGE)
            sizes(size: LARGE)
          }
        }
        Products {
          product {
            productName
            featured {
              content
              tags
              title
              url {
                url
              }
            }
          }
        }
        vertical {
          nodes {
            name
          }
        }
      }
      allInfoSuper(
        where: {
          taxQuery: {
            taxArray: { taxonomy: VERTICAL, terms: $vertical, field: SLUG }
          }
          notIn: $databaseId
        }
        first: 6
      ) {
        nodes {
          title
          uri
        }
      }
    }
  }
`

export default SinglePage
