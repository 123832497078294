import styled from 'styled-components'
import { hoverColor } from '@superlogica/foundation/utils'
import { Link } from 'components/helpers'
import { parseColor, parseSize } from '@superlogica/design-ui'
import { SocialProps } from './Social'

type SocialLinkProps = Required<Pick<SocialProps, 'color' | 'size'>>

export const SocialLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => !['color', 'size'].includes(prop)
})<SocialLinkProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, color, size }) => `
    color: ${parseColor(theme.colors[color])};
    width: ${parseSize(size)};
    height:  ${parseSize(size)};

    &:hover {
      color: ${hoverColor(parseColor(theme.colors[color]), 0.2)};
    }
  `}
`

export const Item = styled.li`
  &:not(:first-of-type) {
    margin-left: 8px;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`

export const List = styled.ul`
  display: flex;
  align-items: center;
`
