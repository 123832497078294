import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Text, Title } from '@superlogica/design-ui'
import { Hero } from 'components/common'
import { Link } from 'components/helpers'
import { Faq } from 'components/app'
import { StaticImage } from 'gatsby-plugin-image'

interface InfoItem {
  data: {
    title: string
    featuredText: {
      text: string
    }
    featuredImage: {
      node: {
        altText: string
        sourceUrl: string
        srcSet: string
        sizes: string
      }
    }
    Products: {
      product: {
        productName: string
        featured: {
          content: string
          tags: string
          title: string
          url: {
            url: string
          }
        }[]
      }[]
    }
  }
  otherPosts: {
    title: string
    uri: string
  }[]
}

const View: React.FC<InfoItem> = ({ data, otherPosts }) => {
  return (
    <Block tag="section" bgColor="babar25">
      <Hero
        edition={`Edição ${data.title}`}
        title="InfoSuper Produtos"
        subtitle="Confira os lançamentos"
        image={
          data.featuredImage ? (
            <img
              src={data.featuredImage.node.sourceUrl}
              alt={data.featuredImage.node.altText}
              sizes={data.featuredImage.node.sizes}
              srcSet={data.featuredImage.node.srcSet}
              loading="lazy"
            />
          ) : (
            <StaticImage
              placeholder="blurred"
              layout="fullWidth"
              src="../../assets/images/img-hero.png"
              objectFit="cover"
              style={{
                width: '100%',
                height: '100%'
              }}
              alt="InfoSuper Produtos"
            />
          )
        }
      />
      <Block
        tag="article"
        paddingTop={{ xxxs: 40, md: 60 }}
        paddingBottom={{ xxxs: 40, md: 60 }}
      >
        <Container>
          <Row justify="center">
            <Col xs={12} md={7}>
              {data.featuredText && (
                <Block
                  padding={24}
                  borderRadius={8}
                  bgColor="sonic500"
                  marginBottom={24}
                >
                  <Text color="babar0">{data.featuredText.text}</Text>
                </Block>
              )}

              <Block>
                <Faq items={data.Products.product} />
              </Block>
            </Col>
            <Col xs={12} md={3}>
              <Block
                border="1px solid"
                borderColor="babar100"
                bgColor="babar0"
                borderRadius={8}
                padding={{ xxxs: '24px 16px', md: '16px 24px' }}
                marginTop={{ xxxs: 24, md: 0 }}
              >
                <Title
                  size="display03"
                  font="heading"
                  color="babar900"
                  marginBottom={16}
                >
                  Outras edições
                </Title>
                <Block display="flex" flexDirection="column">
                  {otherPosts.map((post) => (
                    <Link to={post.uri}>Edição {post.title}</Link>
                  ))}
                </Block>
              </Block>
            </Col>
          </Row>
        </Container>
      </Block>
    </Block>
  )
}
export default View
