import React from 'react'
import { Vector } from '@superlogica/design-ui'

import * as S from './TileButton.styled'

const TileButton: React.FC<UrlProps> = ({
  vector,
  color = 'sonic500',
  label,
  href,
  ...rest
}) => (
  <S.Wrapper to={href} color={color} title={label} {...rest}>
    {label}
    {vector && <Vector name={vector} width={24} height={24} />}
  </S.Wrapper>
)

export default TileButton
